import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { withParams } from '@sl/sl-angular-library';
import { Observable } from "rxjs";
import { IAnswer } from "src/app/shared/models/answer.model";
import { ApplicationSearchCriteria } from "src/app/shared/models/application-search-criteria.model";
import { IApplicationSearchResult } from "src/app/shared/models/application-search-result.model";
import { IApplication, IApplicationSubmission } from "src/app/shared/models/application.model";
import { IInterviewComment } from "src/app/shared/models/interviewComment.model";
import { IQuestionAndAnswer } from "src/app/shared/models/question.model";
import { IRecommendation } from "src/app/shared/models/recommendation.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ApplicationRepository {
  
    private readonly repoUrl: string = `${environment.apiUrl}/applications`;
  
    constructor(private http: HttpClient) { }

    public getApplication(applicationId: number): Observable<IApplication> {
        return this.http.get<IApplication>(`${this.repoUrl}/${applicationId}`);
    }

    public canApplicationRollover():Observable<boolean>{
        return this.http.get<boolean>(`${this.repoUrl}/can-rollover`);
    }

    public getApplications(textCriteria: string, applicationType: string, gpa: number, acyrCode: string, status: string, points: number, gender: string, rating: number, followup: string, page: number): Observable<Array<IApplicationSearchResult>> {
        const searchCriteria = {
            applicant: textCriteria || '',
            type: applicationType || '',
            gpaCriteria: gpa != null ? gpa : '',
            acyrCode: acyrCode || '',
            status: status ? status : '',
            maxPoints: points ? points : '',
            gender: gender || '',
            rating: rating ? rating : '',
            followup: followup || ''
        } as ApplicationSearchCriteria
            
        return this.http.post<Array<IApplicationSearchResult>>(`${this.repoUrl}/admin`, searchCriteria, withParams({ page }));
    }

    public getAssignedApplications(): Observable<Array<IApplication>> {
        return this.http.get<Array<IApplication>>(`${this.repoUrl}/needs-recommendation`);
    }

    public searchForHirableCGLApplications(searchInput: string, page: number): Observable<Array<IApplication>> {
        return this.http.get<Array<IApplication>>(`${this.repoUrl}/hirable-cgls`, withParams({searchInput, page}));
    }

    public createApplication(application: IApplicationSubmission): Observable<IApplication> {
        return this.http.post<IApplication>(this.repoUrl, application);
    }

    public isIntakeFormRequired(id?: number): Observable<boolean> {
        if (id !== undefined) {
            return this.http.get<boolean>(`${this.repoUrl}/${id}/intake-required`);
        } else {
            return this.http.get<boolean>(`${this.repoUrl}/intake-required/me`);
        }
    }

    public getMyPendingApplications(): Observable<Array<IApplication>> {
        return this.http.get<Array<IApplication>>(`${this.repoUrl}/me`);
    }

    public updateRecommendation(applicationId: number, recommendationResponse: IRecommendation): Observable<IApplication> {
        return this.http.put<IApplication>(`${this.repoUrl}/${applicationId}/recommendation`, recommendationResponse);
    }

    public withdrawApplication(applicationId: number): Observable<IApplication> {
        return this.http.put<IApplication>(`${this.repoUrl}/${applicationId}/withdraw`, null);
    }

    public getQuestionsAndAnswers(applicationId: number, questionnaireTypeId:number): Observable<Array<IQuestionAndAnswer>> {
        return this.http.get<Array<IQuestionAndAnswer>>(`${this.repoUrl}/${applicationId}/${questionnaireTypeId}/answers`);
    }

    public getInterviewAnswers(applicationId: number, questionnaireTypeId:number): Observable<Array<IAnswer>> {
        return this.http.get<Array<IAnswer>>(`${this.repoUrl}/${applicationId}/${questionnaireTypeId}/interview/answers`);
    }

    public approveApplication(applicationId: number, hallAssignmentId: number | undefined): Observable<IApplication> {
        return this.http.put<IApplication>(hallAssignmentId ? `${this.repoUrl}/${applicationId}/approved?hallAssignmentId=${hallAssignmentId}` : `${this.repoUrl}/${applicationId}/approved`, null);
    }

    public approveApplicationAsCGL(applicationId: number, hallAssignmentId: number | undefined): Observable<IApplication> {
        return this.http.put<IApplication>(hallAssignmentId ? `${this.repoUrl}/${applicationId}/approved-as-cgl?hallAssignmentId=${hallAssignmentId}` : `${this.repoUrl}/${applicationId}/approved-as-cgl`, null);
    }

    public waitListApplication(applicationId: number): Observable<IApplication> {
        return this.http.put<IApplication>(`${this.repoUrl}/${applicationId}/waitList`, null);
    }

    public denyApplication(applicationId: number, denialReason: string): Observable<IApplication> {
        return this.http.put<IApplication>(`${this.repoUrl}/${applicationId}/denied`, denialReason);
    }

    public rolloverApplication(applicationId: number): Observable<IApplication> {
        return this.http.post<IApplication>(`${this.repoUrl}/rollover`,applicationId);
    }

    public updateInterviewQuestionnaire(applicationId: number, answers: Array<IAnswer>):Observable<null> {
        return this.http.put<null>(`${this.repoUrl}/${applicationId}/interview-questionnaire`, answers);
    } 

    public updateInterviewNotes(applicationId: number, notes: string):Observable<null> {
        return this.http.put<null>(`${this.repoUrl}/${applicationId}/interview-notes`, notes)
    }

    public updateInterviewFollowup(applicationId: number, followUp: boolean):Observable<null> {
        return this.http.put<null>(`${this.repoUrl}/${applicationId}/interview-followup`, followUp)
    }
     
    public requestInterview(applicationId: number): Observable<IApplication> {
        return this.http.put<IApplication>(`${this.repoUrl}/${applicationId}/request-interview`, null);
    }
    
    public completeInterview(applicationId: number, questionnaireTypeId: number): Observable<IApplication> {
        return this.http.put<IApplication>(`${this.repoUrl}/${applicationId}/complete-interview?questionnaireTypeId=${questionnaireTypeId}`, null);
    }

    public revertStatus(applicationId: number): Observable<IApplication> {
        return this.http.put<IApplication>(`${this.repoUrl}/${applicationId}/revert-status`, null);
    }

    public createComment(applicationId: number, note: string): Observable<IInterviewComment> {
        return this.http.post<IInterviewComment>(`${this.repoUrl}/${applicationId}/comment`, note);
    }

    public getAllComments(applicationId: number): Observable<Array<IInterviewComment>> {
        return this.http.get<Array<IInterviewComment>>(`${this.repoUrl}/${applicationId}/comments`);
    }

}