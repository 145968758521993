export enum EStatus {
    AWAITING_RS_RECOMMENDATION = 'RC',
    NOT_RECOMMENDED_BY_RS = 'NR',
    AWAITING_SHEPHERDS_REVIEW = 'RV',
    DENIED_BY_LU_SHEPHERD = 'D',
    INTERVIEW_REQUESTED = 'I',
    FINAL_DECISION = 'FD',
    WITHDRAWN_BY_APPLICANT = 'W',
    IN_ELIGIBLE_POOL = 'EP',
    POSITION_OFFERED = 'PO',
    NOT_HIRED = 'NH',
    HIRED = 'H',
    TERMINATED = 'T',
    STEPPED_DOWN = 'S',
    EXPIRED = 'X',
    OFFER_EXPIRED = "OX",
    WAIT_LISTED = "WL"
}

export function StatusToString(status: EStatus): string {
    return ({
        [EStatus.AWAITING_RS_RECOMMENDATION]: "Awaiting RS Recommendation",
        [EStatus.NOT_RECOMMENDED_BY_RS]: "Not Recommended by RS",
        [EStatus.AWAITING_SHEPHERDS_REVIEW]: "Awaiting Shepherds Review",
        [EStatus.DENIED_BY_LU_SHEPHERD]: "Denied by LU Shepherd",
        [EStatus.INTERVIEW_REQUESTED]: "Interview Requested",
        [EStatus.FINAL_DECISION]: "Awaiting Shepherds Approval",
        [EStatus.WITHDRAWN_BY_APPLICANT]: "Withdrawn by Applicant",
        [EStatus.IN_ELIGIBLE_POOL]: "In Eligible Pool",
        [EStatus.POSITION_OFFERED]: "Position Offered",
        [EStatus.NOT_HIRED]: "Not Hired",
        [EStatus.HIRED]: "Hired",
        [EStatus.TERMINATED]: "Terminated",
        [EStatus.STEPPED_DOWN]: "Stepped Down",
        [EStatus.EXPIRED]: "Application Expired",
        [EStatus.OFFER_EXPIRED]: "Offer Expired",
        [EStatus.WAIT_LISTED]: "Wait Listed"
    })[status];
}

