import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SlCheckboxComponent, SlDatetimeComponent, SlInputComponent, SlPhoneFormatPipe, SlRadioComponent, SlTextareaComponent, ValidationErrorMessageComponent } from '@sl/sl-angular-library';
import { FormDebuggerComponent } from '../components/temp-components/form-debugger/form-debugger.component';
import { ScrollToDirective } from '../core/directives/scroll-to.directive';
import { CloseIconComponent } from './components/close-icon/close-icon.component';
import { DialogModule } from './components/dialog/dialog.module';
import { HeaderComponent } from './components/header/header.component';
import { ImpersonateModalComponent } from './components/impersonate/impersonate-modal.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LuIconComponent } from './components/lu-icon/lu-icon.component';
import { MenuDropdownComponent } from './components/menu-dropdown/menu-dropdown.component';
import { NotificationComponent } from './components/notification.component/notification.component';
import { ProfileInformationComponent } from './components/profile-information/profile-information.component';
import { MultipleAnswerComponent } from './components/questions/multiple-answer/multiple-answer.component';
import { MultipleChoiceComponent } from './components/questions/multiple-choice/multiple-choice.component';
import { ScaleAnswerComponent } from './components/questions/scale-answer/scale-answer.component';
import { ShortAnswerComponent } from './components/questions/short-answer/short-answer.component';
import { YesNoComponent } from './components/questions/yes-no/yes-no.component';
import { ResponsesDisplayComponent } from './components/responses-display/responses-display.component';
import { AcademicYearSelectorComponent } from './components/selectors/academic-year-selector/academic-year-selector.component';
import { ApplicationStatusSelectorComponent } from './components/selectors/application-status-selector/application-status-selector.component';
import { ApplicationTypeSelectorComponent } from './components/selectors/application-type-selector/application-type-selector.component';
import { FollowupSelectorComponent } from './components/selectors/followup-selector/followup-selector.component';
import { GenderSelectorComponent } from './components/selectors/gender-selector/gender-selector.component';
import { GpaSelectorComponent } from './components/selectors/gpa-selector/gpa-selector.component';
import { HallAssignmentSelectorComponent } from './components/selectors/hall-assignment-selector/hall-assignment-selector.component';
import { QuestionTypeSelectorComponent } from './components/selectors/question-type-selector/question-type-selector.component';
import { RatingSelectorComponent } from './components/selectors/rating-selector/rating-selector.component';
import { StatusDisplayComponent } from './components/status-display/status-display.component';
import { ClickOrKeyupOutsideDirective } from './directives/click-outside.directive';
import { AcademicYearCodePipe } from './pipes/academic-year-code.pipe';
import { ApplicationTypePipe } from './pipes/application-type.pipe';
import { FirstLastPipe } from './pipes/first-last.pipe';
import { GenderPipe } from './pipes/gender.pipe';
import { HallDescriptionPipe } from './pipes/hall-description.pipe';
import { StatusCodePipe } from './pipes/status-code.pipe';
import { YearAppliedForPipe } from './pipes/year-applied-for.pipe';
import { AreaSelectorComponent } from './components/selectors/area-selector/area-selector.component';
import { HallSelectorComponent } from './components/selectors/hall-selector/hall-selector.component';
import { FindPersonComponent } from './components/find-person/find-person.component';
import { PeopleSearchComponent } from './components/people-search/people-search.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { DragonDropComponent } from './components/dragon-drop/dragon-drop.component';
import { FilesListComponent } from './components/files-list/files-list.component';
import { FileSizePipe } from './pipes/files-size.pipe';
import { HistoryComponent } from './components/history/history.component';
import { PersonCardComponent } from './components/person-card/person-card.component';
import { ReportModalComponent } from './components/reporting/report-modal.component';


@NgModule({
  declarations: [
    ApplicationTypeSelectorComponent,
    AcademicYearSelectorComponent,
    GpaSelectorComponent,
    RatingSelectorComponent,
    HeaderComponent,
    LuIconComponent,
    ScrollToDirective,
    AcademicYearCodePipe,
    MenuDropdownComponent,
    ImpersonateModalComponent,
    LoadingComponent,
    CloseIconComponent,
    ShortAnswerComponent,
    MultipleChoiceComponent,
    MultipleAnswerComponent,
    YesNoComponent,
    ScaleAnswerComponent,
    FirstLastPipe,
    ApplicationTypePipe,
    ApplicationTypePipe,
    GenderSelectorComponent,
    FormDebuggerComponent,
    ApplicationStatusSelectorComponent,
    AcademicYearSelectorComponent,
    HallAssignmentSelectorComponent,
    NotificationComponent,
    GenderPipe,
    ClickOrKeyupOutsideDirective,
    QuestionTypeSelectorComponent,
    YearAppliedForPipe,
    StatusDisplayComponent,
    StatusCodePipe,
    HallDescriptionPipe,
    ProfileInformationComponent,
    ResponsesDisplayComponent,
    FollowupSelectorComponent,
    AreaSelectorComponent,
    HallSelectorComponent,
    FindPersonComponent,
    PeopleSearchComponent,
    UserCardComponent,
    DragonDropComponent,
    FilesListComponent,
    FileSizePipe,
    HistoryComponent,
    PersonCardComponent,
    ReportModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DialogModule,
    SlInputComponent,
    SlDatetimeComponent,
    SlCheckboxComponent,
    SlTextareaComponent,
    SlRadioComponent,
    SlPhoneFormatPipe,
    ValidationErrorMessageComponent
  ],
  exports: [
    ApplicationTypeSelectorComponent,
    AcademicYearSelectorComponent,
    GpaSelectorComponent,
    RatingSelectorComponent,
    HeaderComponent,
    LuIconComponent,
    ScrollToDirective,
    FormsModule,
    ReactiveFormsModule,
    AcademicYearCodePipe,
    ImpersonateModalComponent,
    LoadingComponent,
    CloseIconComponent,
    CommonModule,
    ShortAnswerComponent,
    MultipleChoiceComponent,
    MultipleAnswerComponent,
    YesNoComponent,
    ScaleAnswerComponent,
    FirstLastPipe,
    ApplicationTypePipe,
    SlTextareaComponent,
    DialogModule,
    ApplicationTypePipe,
    GenderSelectorComponent,
    ApplicationStatusSelectorComponent,
    FormDebuggerComponent,
    ApplicationStatusSelectorComponent,
    HallAssignmentSelectorComponent,
    NotificationComponent,
    GenderPipe,
    SlCheckboxComponent,
    ClickOrKeyupOutsideDirective,
    QuestionTypeSelectorComponent,
    YearAppliedForPipe,
    StatusDisplayComponent,
    StatusCodePipe,
    SlRadioComponent,
    HallDescriptionPipe,
    SlPhoneFormatPipe,
    ProfileInformationComponent,
    ResponsesDisplayComponent,
    FollowupSelectorComponent,
    AreaSelectorComponent,
    HallSelectorComponent,
    FindPersonComponent,
    PeopleSearchComponent,
    UserCardComponent,
    DragonDropComponent,
    FilesListComponent,
    FileSizePipe,
    HistoryComponent,
    PersonCardComponent,
    ReportModalComponent,
    ValidationErrorMessageComponent
  ],
  providers: [ // list pipes used via TypeScript here
    DatePipe
  ] 
})
export class SharedModule { }
