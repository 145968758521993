import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SlModalComponent, SlModalService } from '@sl/sl-angular-library';
import { Observable, forkJoin, switchMap, take } from 'rxjs';
import { EImages } from 'src/app/core/enums/images.enum';
import { RoleGroup } from 'src/app/core/enums/role-groups';
import { ERole } from 'src/app/core/enums/roles.enum';
import { ApplicationWindowService } from 'src/app/core/services/application-window.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { PeopleService } from 'src/app/core/services/people.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { RoleService } from 'src/app/core/services/roles.service';
import { EApplicationStatus } from 'src/app/shared/enums/application-status.enum';
import { IApplicationWindow } from 'src/app/shared/models/application-window.model';
import { IApplication } from 'src/app/shared/models/application.model';
import { IUserProfile } from 'src/app/shared/models/user-profile.model';
import { IViewableAcademicYears } from 'src/app/shared/models/viewable-academic-years.model';

@Component({
  selector: 'app-student-landing-page',
  templateUrl: './student-landing-page.component.html',
  styleUrls: ['./student-landing-page.component.scss']
})
export class StudentLandingPageComponent implements OnInit {
  public windowClosedModal: SlModalComponent;

  protected welcomeMessage = "Thank you for helping to fulfill God's calling by praying for and serving your fellow students."
  protected welcomeMessageNS = "Help fulfill God's calling by praying for and serving your fellow students."
  public welcomeImg: string;

  protected rsImg = EImages.RS;
  protected csImg = EImages.CS;
  protected asImg = EImages.AS;
  protected cglImg = EImages.CGL;

  protected isShepherd = false;
  protected profile: IUserProfile;

  protected currentApplicationWindow: IApplicationWindow;
  protected nextApplicationWindow: IApplicationWindow;
  protected isRolloverActive = false;
  public showRolloverNotification = false;

  public isFutureRS = false;
  public isRS = false;

  protected cards: Array<IApplicationWindow | IApplication>;

  constructor(
    private roleService: RoleService,
    private applicationWindowService: ApplicationWindowService,
    private profileService: ProfileService,
    private applicationService: ApplicationService,
    private router: Router,
    private slModalService: SlModalService,
    private peopleService: PeopleService,
  ) { }

  ngOnInit(): void {
    this.isShepherd = this.roleService.hasAnyRole(RoleGroup.STUDENT_SHEPHERDS);
    this.isFutureRS = this.roleService.hasRole(ERole.FUTURE_RS);
    this.isRS = this.roleService.hasRole(ERole.CURRENT_RS);
    
    this.welcomeImg = this.getWelcomeImg();

    this.setupApplicationCards();

    // const profileObserver: Observable<IUserProfile> = this.profileService.getMyProfile();
    const currentApplicationWindowObserver: Observable<Array<IApplicationWindow>> = this.applicationWindowService.getActiveApplicationWindows("RS");

    forkJoin([currentApplicationWindowObserver]).pipe(
      take(1),
      switchMap(([applicationWindows]) => {
        if (applicationWindows && applicationWindows.length) {
          this.currentApplicationWindow = applicationWindows[0];
          this.nextApplicationWindow = applicationWindows[1];
        }
        return this.applicationService.canApplicationRollover();
      })
    ).subscribe(x => {
      if (x === true) {
        if (!this.rolloverAlreadyDismissed()) {
          this.showRolloverNotification = true;
        }
      }
    });
  }

  public applyNowAriaLabel(typeDescription: string): string {
    return "Apply Now for "+typeDescription;
  }

  public setupApplicationCards(): void {
    this.peopleService.getViewableYears().subscribe((awa: IViewableAcademicYears) => {
      // remove any applications that have been withdrawn, denied, not recommended, or expired
      const sortedApps = awa.applications
        .filter((app: IApplication) => app.status.statusCode !== EApplicationStatus.WITHDRAWN_BY_APPLICANT
                                    && app.status.statusCode !== EApplicationStatus.DENIED_BY_LU_SHEPHERD
                                    && app.status.statusCode !== EApplicationStatus.NOT_RECOMMENDED_BY_RS
                                    && app.status.statusCode !== EApplicationStatus.EXPIRED)
        .sort((a, b) => Number(a.acyrCode) - Number(b.acyrCode));
  
      // remove any application windows where there was an application that was denied or not recommended so they cannot apply again
      const deniedOrNotRecommededApps = awa.applications
        .filter((app: IApplication) => app.status.statusCode === EApplicationStatus.DENIED_BY_LU_SHEPHERD
                                  || app.status.statusCode === EApplicationStatus.NOT_RECOMMENDED_BY_RS
                                  || app.status.statusCode === EApplicationStatus.EXPIRED)
        .sort((a, b) => Number(a.acyrCode) - Number(b.acyrCode));
      let applicationWindows = awa.applicationWindows.filter(aw =>         
        !deniedOrNotRecommededApps.some((a) => a.applicationType === aw.applicationType && a.acyrCode === aw.academicYear.acyrCode));
  
      // remove any applications windows having an academic year where an application is applied for; 
      // essentially once one applies for a position, they cannot apply for anything else in that academic year
      const acyrCodesToRemove = sortedApps.map((x: IApplication) => x.acyrCode);
      applicationWindows = applicationWindows.filter((y: IApplicationWindow) => !acyrCodesToRemove.includes(y.academicYear.acyrCode));
  
      // cards will be ordered by application windows / available positions followed by current and future applications that have been submitted regardless if outside their application window
      this.cards = [...applicationWindows, ...sortedApps];
    });
  }

  onApplyClicked(applicationType: string): void {
    this.applicationWindowService.getActiveApplicationWindows(applicationType).subscribe(appWindow => {
      const currentDate = new Date();
      const openWindows = appWindow.filter(aw => {
        if (aw.openDate && aw.closeDate) {
          return currentDate >= new Date(aw.openDate) && currentDate <= new Date(aw.closeDate)
        }

        return false;
      });

      if (openWindows.length > 0) {
        this.router.navigate(['apply', applicationType.toLowerCase()]);
      } else {
        this.openWindowClosedModal();
      }
    });
  }

  dismissNotification(dismiss: boolean = false) {
    this.showRolloverNotification = dismiss;
    if (this.currentApplicationWindow?.academicYear) {
      localStorage.setItem('rollover-' + this.currentApplicationWindow.academicYear.acyrCode, 'dismissed');
    }

  }

  rolloverAlreadyDismissed(): boolean {
    return localStorage.getItem('rollover-' + this.currentApplicationWindow.academicYear.acyrCode) ? true : false;
  }

  followRoute() {
    this.router.navigate(['/profile']);
  }

  public formattedYear(year: string, numToAdd: number): number {
    return Number(year) + numToAdd;
  }

  public getWelcomeImg(): string {
    if (this.roleService.hasRole(ERole.CURRENT_RS) || this.roleService.hasRole(ERole.FUTURE_RS)) {
      return EImages.NS;
    } else {
      return EImages.NS;
    }
  }

  public openWindowClosedModal() {
    this.windowClosedModal = this.slModalService.createModal({
      title: "Application Window Closed",
      message: "Applications are no longer being accepted. Please contact the Office of Spiritual Development to be considered for a position.",
      class: 'error-modal',
    })
  }

  public getCards(): Array<IApplicationWindow | IApplication> {
    return this.cards;
  }

}
