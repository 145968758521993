function getOrigin() {
  if (!('origin' in window.location) || !window.location.origin) {
    return window.location.protocol + '//' + window.location.hostname + (window.location.port
      ? ':' + window.location.port
      : ''
    );
  }

  return window.location.origin;
}

function getEnvironmentApiUrl() {
  const origin = getOrigin(); // https://shepherds-[env].apps.lyn-cre01.liberty.edu

  return `${origin}/api`;
}

export const environment = {
  production: true,
  appName: 'shepherds',
  apiUrl: getEnvironmentApiUrl(),
  unauthenticatedUrl: function(): string {
    return `${environment.apiUrl}/login/auth-and-redirect?url=${encodeURIComponent(window.location.href)}`;
  }
};
