import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IId } from "@sl/sl-angular-library/lib/models/id.model";
import { Observable } from "rxjs";
import { IConductInformation } from "src/app/shared/models/conduct-information.model";
import { IUploadedFile } from "src/app/shared/models/file-upload.model";
import { IUserProfile } from "src/app/shared/models/user-profile.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ProfileRepository {

    private readonly repoUrl: string = `${environment.apiUrl}/profile`;

    constructor(private http: HttpClient) { }

    public getProfile(pidm: number): Observable<IUserProfile> {
        return this.http.get<IUserProfile>(this.repoUrl, {params: {'pidm': pidm}});
    }

    public getMyProfile(): Observable<IUserProfile> {   
        return this.http.get<IUserProfile>(`${this.repoUrl}/my-profile`);
    }

    public getConductInformation(pidm: number): Observable<IConductInformation> {
        return this.http.get<IConductInformation>(`${this.repoUrl}/${pidm}/conduct`);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public putOnlineContactInfo(onlineContactInfo: string, pidm:any): Observable<IUserProfile> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.http.put<any>(`${this.repoUrl}/${pidm}/contact`,onlineContactInfo);
    }

    public saveFileUpload(formData: FormData): Observable<IId> {
        return this.http.post<IId>(`${this.repoUrl}/uploads`, formData);
    }

    public deleteFileUpload(fileUploadId: number): Observable<null> {
        return this.http.delete<null>(`${this.repoUrl}/uploads/${fileUploadId}`);
    }

    public getLatestFile(pidm: number, uploadType: number): Observable<IUploadedFile> {
        return this.http.get<IUploadedFile>(`${this.repoUrl}/${pidm}/${uploadType}/uploads/latest`);
    }

    public downloadFile(uploadId: number): Observable<Blob> {
        return this.http.get(`${this.repoUrl}/uploads/${uploadId}`, {responseType: 'blob' });
    }
}